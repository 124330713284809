<template>
  <div class="communication">
    <div class="left">
<!--      <p>角膜接触镜介绍</p>-->
      <div v-for="(item, idx) in list" @click="teb(idx)" :class="idxs==idx?'xz':''"><i class="el-icon-caret-right"></i>{{item.ICFName}}</div>
      <div @click="teb(list.length+1, 1)" :class="idxs==list.length+1?'xz':''"><i class="el-icon-caret-right"></i>取镜片记录</div>
<!--      <div @click="idx=1" :class="idx==1?'xz':''"><i class="el-icon-caret-right" ></i>RGP协议</div>-->
<!--      <div @click="idx=2" :class="idx==2?'xz':''"><i class="el-icon-caret-right" ></i>SCL协议</div>-->
<!--      <div @click="idx=3" :class="idx==3?'xz':''"><i class="el-icon-caret-right" ></i>注意事项</div>-->
<!--      <div @click="idx=4" :class="idx==4?'xz':''"><i class="el-icon-caret-right" ></i>患者教育</div>-->
<!--      <el-button type="primary"style="margin-top: 50px;" @click="dy">打印</el-button>-->
    </div>
    <div class="right">
      <iframe v-if="idxs < list.length" class="znr" ref="prints" :src="src"></iframe>
      <div v-if="idxs > list.length" style="display: flex;align-items: start;margin-top: 10vh;">
        <UPfile :type="'ICF'"></UPfile>
      </div>
<!--      <h3 v-if="idxs == 0">OK镜 &#45;&#45;角膜接触镜治疗同意书</h3>-->
<!--      <h3 v-if="idxs == 1">RGP &#45;&#45;角膜接触镜治疗同意书</h3>-->
<!--      <h3 v-if="idxs == 2">SCL &#45;&#45;角膜接触镜治疗同意书</h3>-->
<!--      <div style="text-align: left;">-->
<!--        我因近视自愿在%@接受角膜塑形镜近视矫正，经眼科医师及验配师细心解释，我已了解有关角膜塑形镜矫治近视眼的原理和过程，并且愿意遵守和配合下列条款。条款内容如下：-->
      <!--        <p>1、医生会尽力通过治疗控制并降低本人的近视度数，本人对由于个体差异及其他多种因素的影响而出现的矫正不足表示理解，比如由于各种原因导致白天不一定都有1.0（5.0）的矫正视力。配戴角膜塑形镜主要目的是控制度数加深、延缓度数增长，但不能保证度数不加深。</p>-->
      <!--        <p>2、能理解治疗期间可能会出现轻度不适如重影、视力波动等，并愿意在医生指导下坚持配戴治疗；为确保疗效，遵从使用专业角膜塑形镜护理液、润眼液等辅助用品。</p>-->
      <!--        <p>3、在治疗期间严格遵循角膜塑形镜操作规范，配合医生治疗。因季节或者配戴隐形卫生的问题，会有结膜炎、角膜点染等并发症，如期间有任何不适，如眼红、痛、流泪、畏光等，我会立即停戴并来医院就诊，遵照医嘱配合治疗，以维护本人眼部健康并保证矫治效果。如未按规范操作或在治疗中途未能按期复诊或自行中断治疗，所造成的度数回升或其他无法预知的后果，我会自行负责。</p>-->
      <!--        <p>4、治疗期间如出现与本治疗无关的眼病，不属医方负责。</p>-->
      <!--        <p>5、本人在确定开始治疗时付清全部费用。治疗费用包括：镜片材料费.后期没有其他问题只需支付护理液的费用。</p>-->
      <!--        <p>6、因角膜塑形镜是应用高分子合成材料制成的高透氧硬性镜片，镜片精致，有一定脆性且其成本昂贵，使用者在进行镜片清洗及使用时需格外小心。镜片经验收交付后，如因使用者本人疏忽而发生的镜片遗失、破损由使用者自行负责,无论取镜时间长短。镜片重新定做应按规定另付定做镜片费用。</p>-->
      <!--        <p>7、我已了解如一段时间停戴，近视度数会回升，故达到最佳矫正效果后仍需长期配戴角膜塑形镜。镜片的正常使用寿命为2年左右，镜片使用到期后建议更换（需另付款）以维护眼的健康及矫治疗效。</p>-->
      <!--        <p>8、对于个别患者度数较高、角膜曲率较平，但坚持要配角膜塑形镜，可能无法达到白天摘镜的效果，需要再配一副辅助的眼镜。完全理解并同意。</p>-->
      <!--        <p>9、镜片成本昂贵，一旦下单，就无法退货，因为验配的问题前三个月可以更换处方。</p>-->
      <!--        <p>10、此同意书一式两份。</p>-->
      <!--        <p>本人已详细阅读以上条款且完全理解，并愿意遵守以上所有规定，同意接受角膜塑形镜近视治疗。(十八岁以下由家长或监护人签名)</p>-->
<!--      </div>-->
    </div>
<!--    <div class="right" v-if="idxs == 3">-->
<!--    </div>-->
<!--    <div class="right M" v-if="idxs == 4">-->
<!--      <p>.注意饮食习惯，少吃甜食，不偏食挑食</p>-->
<!--      <p>.注意用眼姿势，用眼环境</p>-->
<!--      <p>.多做户外活动（每天确保2小时以上的户外活动）</p>-->
<!--      <p>.定期检查视力（每3个月或半年要复查）</p>-->
<!--    </div>-->

    <div class="bottom">
      <el-button type="primary" v-if="showCs" @click="getqm(1)">顾客签名</el-button>
      <el-button type="primary" v-if="showYs" @click="getqm(2)">医生签名</el-button>
      <el-button type="primary" v-if="showSgs" @click="getqm(3)">视光师签名</el-button>
<!--      <el-button type="primary" @click="tj">提交签名</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "communication",
  data() {
    return {
      idxs: 0,
      list: [],
      src: '',
      showCs: false,
      showYs: false,
      showSgs: false
    }
  },
  created() {
    this.cshTime('contact_protocal')

    this._api.Company.getIcf()
    .then(res => {

      if (res.GetListResult && res.GetListResult.length) {
        this.list = res.GetListResult
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].ICFURL) {
            this.list[i].ICFURL = this.list[i].ICFURL.replace('{CustomerId}', this.$store.state.users.CsUser.CustomerId)
            this.list[i].ICFURL = this.list[i].ICFURL.replace('{ICFId}',  this.list[i].ICFId)
            this.list[i].ICFURL = this.list[i].ICFURL.replace('{ChooseId}', this.$store.state.physicianVisits.xzConsulting)
            this.list[i].ICFURL = this.list[i].ICFURL.replace('{ExpertId}', this.$store.state.users.employees.ExpertId)
          }
        }

        this.src = this.list[0].ICFURL
        if (this.list[0].ICFPicTypeCustomer) this.showCs = true
        if (this.list[0].ICFPicTypeDoctor) this.showYs = true
        if (this.list[0].ICFPicTypeOptometrist) this.showSgs = true

        // console.log(this.list[0])
      }
      //+ '?ICFId=' + this.list[0].ICFId + '&CustomerId='+ this.$store.state.users.CsUser.CustomerId+'&ChooseId='+this.$store.state.physicianVisits.xzConsulting+'&ExpertId='+this.$store.state.users.employees.ExpertId

    })
  },
  methods: {
    teb(idx, type='') {
      this.idxs = idx
      if (type) {
        this.showCs = false
        this.showYs = false
        this.showSgs = false
        return
      }
      // let srcs =  this.list[idx].ICFURL
      // srcs = srcs.replace("{ICFId}",  this.list[idx].ICFId)
      // srcs = srcs.replace("{CustomerId}",  this.$store.state.users.CsUser.CustomerId)
      // srcs = srcs.replace("{ChooseId}",  this.$store.state.physicianVisits.xzConsulting)
      // srcs = srcs.replace("{ExpertId}",  this.$store.state.users.employees.ExpertId)
      this.src = this.list[idx].ICFURL
      console.log(this.list[idx])
      this.list[idx].ICFPicTypeCustomer ? this.showCs = true : this.showCs = false
      this.list[idx].ICFPicTypeDoctor ? this.showYs = true : this.showYs = false
      this.list[idx].ICFPicTypeOptometrist ? this.showSgs = true : this.showSgs = false
    },
    getqm(type) {
      if (type == 2 || type == 3) {
        if (!this.$store.state.expertClinic.qmUrl) {
          return this.$alert('请在专家在线端设置电子签名！', '提示')
        }
        let item = type==2?this.list[this.idxs].ICFPicTypeDoctor:this.list[this.idxs].ICFPicTypeOptometrist
        this._api.expertClinic.ZqtysSgin(item, this.list[this.idxs].ICFId)
            .then(res => {
              // console.log(res)
              if (res.ReturnCode == 5000) {
                this.$alert('提交签名成功!', '提示')
              }else {
                this.$alert(res.displaymsg, '提示')
              }
            })
      }
      if (type == 1) {
        // console.log()
        this._api.expertClinic.ZqtysCsSgin(this.list[this.idxs].ICFPicTypeCustomer, this.list[this.idxs].ICFId)
            .then(res => {
              // console.log(res)
              if (res.ReturnCode == 5000) {
                this.$alert('提交签名成功!', '提示')
              }else {
                this.$alert(res.displaymsg, '提示')
              }
            })
      }
      if ( this.$store.state.Time.timeObj.contact_protocal.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_protocal.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_protocal.EXIT)
        this._api.publicApi.addTime('contact_protocal', times)
        this.jsTime('contact_protocal', 1)
      }
    },
    tj(type) {

    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.contact_protocal.ENTER != 0) {
      this.jsTime('contact_protocal')
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
  .communication {
    width: 100%;
    display: flex;
    justify-content: start;
  }
  .left {
    padding-top: 10vh;
    padding-left: 8vw;
    text-align: left;
    p {font-size: 17px;}
    div {
      padding: .2rem 0;
      border-bottom: 1px solid #000000;
      cursor: pointer;
      &:hover {
        background: #efefef;
      }
    }
    .xz {background: #efefef}
  }
  .right {
    display: flex;
    //align-items: center;
    //justify-content: center;
    width: 55vw;
    height: 70vh;
    padding-left: 5vw;
    overflow: hidden;
  }
  .N {
    display: block;
    width: 73vw;
    height: 80vh;
    overflow-y: hidden;
    h3 {padding: .3rem 0; text-align: center;}
    div {
      height: 60vh;
      overflow-y: auto;
      padding-bottom: 10px;
      padding-right: 10px;
    }
  }
  .M {
    display: block;
    padding-top: 10vh;
    text-align: left;
    p {
      padding: .2rem;
    }
  }
  .bottom {
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: center;
    bottom: .3rem;
  }
  .znr {
    width: 100%;
    height: 65vh;
    margin-top: 5vh;
  }
</style>
